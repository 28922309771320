import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from '@latitude/link';
export const _frontmatter = {
  "id": "terms-of-use",
  "title": "Terms of Use",
  "order": 1
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Effective as of 7 September 2021.`}</p>
    <p>{`Please read this page carefully before using the Latitude Help Centre.`}</p>
    <p>{`The Latitude Help Centre (the '`}<strong parentName="p">{`Help Centre`}</strong>{`') provides online servicing forms and useful information for Latitude's products and services.  The Help Centre includes the following websites:`}</p>
    <ul>
      <li parentName="ul">
        <Link target="_blank" rel="noopener noreferrer" href="https://gemfinance.zendesk.com/hc/en-nz" trackEventData={{
          location: 'Terms of Use'
        }} trackId="tc-zendesk-help-center" mdxType="Link">Zendesk Help Centre</Link>
      </li>
      <li parentName="ul">
        <Link target="_blank" rel="noopener noreferrer" href="https://gemfinance.zendesk.com/hc/en-nz/requests/new?ticket_form_id=360000127417" trackEventData={{
          location: 'Terms of Use'
        }} trackId="tc-hardship-application-form" mdxType="Link">Hardship Care Form</Link>
      </li>
    </ul>
    <p>{`By accessing or using the Help Centre, including downloading materials from the Help Centre, you agree to abide by these terms and conditions (the '`}<strong parentName="p">{`Terms of Use`}</strong>{`').  If you do not agree to abide by the Terms of Use, you should immediately stop accessing or using the Help Centre.`}</p>
    <p>{`The Help Centre is operated by Latitude Financial Services Australia Holdings Pty Ltd (ABN 46 603 161 100) for the benefit of itself and/or its related bodies corporate.  In these Terms of Use a reference to 'us', 'our', 'we' or 'Latitude' is a reference to the following companies either collectively or individually as applicable in context:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Latitude Financial Services Ltd (Company Number 5624865);`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Latitude Innovation Holdings Ltd (Company Number 7146597);`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Latitude Financial Services Australia Holdings Pty Ltd (ABN 46 603 161 100);`}</p>
      </li>
    </ul>
    <p>{`The terms and conditions that apply the Latitude Mobile App or Online Service Centre are available within those services and for the terms and conditions for Latitude's other websites go to `}</p>
    <Link target="_blank" rel="noopener noreferrer" href="/terms-and-conditions/" trackEventData={{
      location: 'Terms of Use'
    }} trackId="tc-latitude" mdxType="Link">www.gemfinance.co.nz/terms-and-conditions/</Link>.
    <h4>{`1. Use of the Help Centre`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Subject to these Terms of Use, we grant you a non-exclusive, non-transferable, limited right to access, use and display the Help Centre and the information thereon.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`We authorise you to view and download the information ('`}<strong parentName="p">{`Materials`}</strong>{`') at the Help Centre only for your personal, non-commercial use.  This authorisation is not a transfer of title in the Materials or copies of the Materials and is subject to the following restrictions:`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`you must retain, on all copies of the Materials downloaded, all copyright and other proprietary notices contained in the Materials;`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`you may not modify the Material in any way or reproduce or publicly display, perform, or distribute or otherwise use them for any public or commercial purpose; and`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`you must not transfer the Materials to any other person unless you give them notice of, and they agree to accept, the obligations arising under these Terms of Use.`}</p>
          </li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`We may terminate the authorisation, rights and licence given above and, upon such termination, you shall immediately destroy all Materials.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`You agree not to:`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`interfere or disrupt, or attempt to interfere or disrupt, the proper working of the Help Centre or any network connected to the Help Centre;`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`use the Help Centre to upload any harmful or destructive files (for example, viruses, corrupted files, malware, etc.); or`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`use the Help Centre to share, upload, advocate or encourage any information, content, imagery, material or activity that is inappropriate (for example, information or material that is illegal, violent, hateful, discriminatory, defamatory, harassing or bullying, obscene, contain nudity or sexually explicit material, incorporates the proprietary material of another, etc.).`}</p>
          </li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`You agree to:`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`abide by any and all additional restrictions displayed on the Help Centre as it may be updated from time to time; and`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`comply with all applicable laws and regulations, in force from time to time, when using the Help Centre.`}</p>
          </li>
        </ul>
      </li>
    </ol>
    <h4>{`2. Disclaimer`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`The Help Centre and Materials are provided ‘as is’ and may have faults or contain inaccuracies or errors. Except in the instance of warranties implied by law (including any applicable provisions of the Fair Trading Act 1984 and Consumer Guarantees Act 1993) that cannot be excluded, we make no warranties or representations of any kind, including but not limited to:`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`the availability, accessibility, security, stability or reliability of the Help Centre;`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`the accuracy or completeness of Materials or the reliability of any advice, opinion, statement or other information displayed or distributed through the Help Centre; and`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`merchantability, non-infringement or fitness for any particular purpose of the Help Centre or information and Material available on the Help Centre, and`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`we specifically disclaim any liability or responsibility for any faults, failures or interruptions in the Help Centre or the accuracy, timeliness, completeness, security or reliability of any information or communications made using the Help Centre.`}</p>
          </li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`You acknowledge that:`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`your access to and use of the Help Centre is at your own risk; and`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`any reliance on any matter covered by clause 2.1 is at your own risk.`}</p>
          </li>
        </ul>
      </li>
    </ol>
    <h4>{`3. Limitation of liability`}</h4>
    <ol>
      <li parentName="ol">{`In no event shall we or any of our related bodies corporate be liable to any entity for any direct, indirect, special, consequential or other damages (including, without limitation, any lost profits, business interruption, loss of information or programs or data (including on your information handling system) that are related to the use of, or the inability to use, the content, materials, and functions of the Help Centre or any linked website, even if we are expressly advised of the possibility of such damages.`}</li>
    </ol>
    <h4>{`4. Changes to the Help Centre`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`We reserve the right to do any of the following at any time and without notice or liability:`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`correct any faults, inaccuracies or errors in any part of the Help Centre;`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`terminate, change, suspend, replace or discontinue the Help Centre or any aspect of the Help Centre; or`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`make any other changes to the Help Centre, the Materials and the products, programs, features, services or prices (if any) described or available in the Help Centre.`}</p>
          </li>
        </ul>
      </li>
    </ol>
    <h4>{`5. Changes to the Terms of Use`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`We reserve the right to vary any part of Terms of Use, at any time.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Changes in these Terms of Use will be effective when the varied Terms of Use are published on the Help Centre.  We do not have to indicate on the Help Centre or otherwise notify you that there has been any variation to the Terms of Use and you should revisit this page when you access the Help Centre to review the Terms of Use.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Your continued use of the Help Centre after any variation will be considered acceptance of the varied Terms of Use.  If you do not agree to abide by the variation to the Terms of Use, you should immediately stop accessing or using the Help Centre and destroy all Materials.`}</p>
      </li>
    </ol>
    <h4>{`6. Information provided by you`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Unless you are using one of the Help Centre's designated online service forms, we do not want you to, and you should not, send any confidential or proprietary information to Latitude via the Help Centre.  You agree that any such information or materials that you or individuals acting on your behalf provide to us will not be considered confidential or proprietary.  By providing any such information or materials to us, you grant to us an unrestricted, irrevocable, worldwide, royalty-free licence to use, reproduce, display, publicly perform, transmit and distribute such information and materials, and you further agree that we are free to use any ideas, concepts or know-how that you or individuals acting on your behalf provide to us.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`When using one of the Help Centre's designated online service forms you will be submitting your personal information and you should first read and understand our Privacy Policy and any applicable privacy notices.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`You agree not to upload, send or otherwise provide us anything in that would be contrary to clause 1.4 above.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Our Privacy Policy explains how we manage your personal information, including collection, use and disclosure.  A copy is available online at `}</p>
        <Link target="_blank" rel="noopener noreferrer" href="/privacy/" trackEventData={{
          location: '6. Information provided by you'
        }} trackId="tc-privacy" mdxType="Link">www.gemfinance.co.nz/privacy/</Link>.
      </li>
    </ol>
    <h4>{`7. Third-party sites`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`As a convenience to you, we may provide links to websites operated by other entities on the Help Centre.  If you use these websites, you will leave the Help Centre.  If you decide to visit any linked site, you do so at your own risk and it is your responsibility to take all protective measures to guard against viruses or other destructive elements.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`We make no warranties or representations regarding, and do not endorse, any linked websites or the information appearing thereon or any of the products or services described thereon.  Links do not imply that we or the Help Centre sponsors, endorses, is affiliated or associated with, or is legally authorised to use any trademark, trade name, logo or copyright symbol displayed in or accessible through the links, or that any linked site is authorised to use any trademark, trade name, logo or copyright symbol of Latitude or any of its affiliates or subsidiaries.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`If you wish to link to any part of the Help Centre, you must obtain our prior written consent.`}</p>
      </li>
    </ol>
    <h4>{`8. Copyright and trademarks`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`The Help Centre, including all Materials, is subject to copyright and protected by worldwide copyright laws and treaty provisions.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`You agree to comply with all copyright laws worldwide in your use of the Help Centre and to prevent any unauthorised copying of the Materials.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Except as expressly provided herein, we do not grant any express or implied right to you under any patents, trademarks, copyrights or trade secret information.`}</p>
      </li>
    </ol>
    <h4>{`9. International users and choice of law`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`The Help Centre is controlled, operated and administered by us from our offices within Australia.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`We make no representation that materials at this site are appropriate or available for use at other locations outside of New Zealand and access to them from territories where their contents are illegal is prohibited.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`You must not use the Help Centre or export the Materials in violation of New Zealand export laws and regulations.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`If you access the Help Centre from a location outside New Zealand, you are responsible for compliance with all local laws.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`These Terms of Use are governed by the laws of New Zealand, without giving effect to its conflict of laws provisions.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Any cause of action you may have with respect to your use of the Help Centre must be commenced within the period provided by any applicable statute of limitations.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Any cause of action you may have with respect to your use of the Help Centre must be commenced within the period provided by any applicable statute of limitations.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`If for any reason a court of competent jurisdiction finds any provision of these Terms of Use or portion thereof, to be unenforceable, that provision shall be enforced to the maximum extent permissible so as to effect the intent of the Terms of Use, and the remainder of these Terms of Use shall continue in full force and effect.`}</p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      