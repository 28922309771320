import React from 'react';
import Helmet from 'react-helmet';

import { SecondaryHero } from '@latitude/hero';
import { Heading2 } from '@latitude/heading';
import { ALIGN } from '@latitude/core/utils/constants';
import Section from '@latitude/section';

import Layout from '@/components/layout';

import TermOfUsePage from '../../data/pages/terms-of-use/terms-of-use-page.mdx';
import PageData from '../../data/pages/terms-of-use/index.json';
import '../../../sass/_table.scss';

const TermsOfUse = ({ location }) => {
  return (
    <Layout location={location}>
      <main className="navigation-spacer">
        <Helmet>
          <title>{PageData.metaTitle} </title>
          <meta name="description" content={PageData.metaDesc} />
          <link
            href="https://www.gemfinance.co.nz/help-centre/terms-of-use/"
            rel="canonical"
          />
        </Helmet>

        <SecondaryHero heading="Latitude Help Centre" />

        <Section id="terms">
          <Heading2 align={ALIGN.CENTER}>Terms of Use</Heading2>
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <TermOfUsePage />
            </div>
          </div>
        </Section>
      </main>
    </Layout>
  );
};

export default TermsOfUse;
